import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders product options to select a product variant.
 */
class ProductVariantSelect extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-selected-variant-id'
    ];
  }

  readonly dataset!: {
    /**
     * Currently selected product variant id.
     */
    selectedVariantId: string;

    /**
     * Controls whether this element may update the url with a new variant parameter when a new
     * variant is selected. For Lange this happens all the time, but for other brands where we
     * eventually plan to share this code, this is not always true. For example, on one of our other
     * brands you can change the selected variant on certain collection pages.
     *
     * This is not an observed attribute. We only set this in the connected callback during init and
     * do not update its value again later. We do not expect it to ever change.
     */
    updateUrl: 'false' | 'true';
  };

  public shadowRoot!: ShadowRoot;
  private product: Partial<Product>;

  /**
   * The currently selected variant id. When a user is viewing a product, they are actually always
   * viewing a specific variant of the product. If the variant is not explicit, e.g. there is no
   * variant url parameter, then Shopify selects the default variant and then that variant is what
   * Liquid exposes as the selected variant and that is what initializes the selected variant.
   */
  private selectedVariantId?: number;

  constructor() {
    super();
    this.product = { option_groups: [] };
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.selectedVariantId = parseInt(this.dataset.selectedVariantId, 10);

    const slot = this.shadowRoot.querySelector<HTMLSlotElement>(
      'slot[name="product-variant-select-data"]');

    // This event only triggers when a slot is added or removed
    if (slot) {
      slot.addEventListener('slotchange', this.onSlotChange.bind(this));
    }

    this.onSlotChange(null);

    // TODO: the following code makes an incorrect assumption that the slot has been
    // filled correctly before the connectedCallback() was called. This is not guaranteed.
    // this.product may still be empty at this stage and the code that follows needs to be fixed

    // Remove this element when it is not needed (i.e. there can be no variant options on (1) simple
    // bundles or (2) when only one option group exists with a single option - this is a product
    // without variants).

    // TODO: clarify the above comment, there is no such thing as a product with 0 product variants,
    // all Shopify products have a variant, if no variant is explicitly configured then a product
    // has a default variant with the title "Default Title"

    // TODO: this might be the cause of a node found error because we are trying to remove ourselves
    // in the connected callback, investigate

    if (this.product.option_groups.length === 1 &&
      this.product.option_groups[0].options.length === 1) {
      this.remove();
      return;
    }

    // If a variant url parameter is detected at the time of dom attachment, then check whether the
    // url specified variant corresponds to the id of one of the variant elements specified as a dom
    // descendant of this element. If a matching nested variant is found, update the selected id.
    // If there is no match, remove the invalid parameter from the url.

    // The url parameter overwrites the dataset attribute for the selected variant id.

    const url = new URL(location.href);
    if (url.searchParams.has('variant')) {
      const variantId = parseInt(url.searchParams.get('variant'), 10);
      const variants = this.getVariants();
      const variant = variants.find(variant => variant.id === variantId);
      if (variant) {
        // We want to minimize the number of dom interactions so we only set the value when the
        // value is different. The values is generally the same because Shopify initializes the
        // selected variant server side from the url parameter as well, which determines what the
        // selected variant id is in liquid, which determines the javascript state.
        if (variantId.toString() !== this.dataset.selectedVariantId) {
          this.dataset.selectedVariantId = variantId.toString();
          if (this.product) {
            this.product.selected_variant_id = variantId;
          }
        }
      } else {
        url.searchParams.delete('variant');
        history.replaceState({}, '', url);
      }
    }

    this.createForm();
    this.update();

    const form = this.shadowRoot.querySelector('form');
    form?.addEventListener('change', this.onFormChange.bind(this));
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name === 'data-selected-variant-id' && oldValue !== newValue) {
      this.selectedVariantId = parseInt(newValue, 10);
      this.product.selected_variant_id = parseInt(newValue, 10);
    }
  }

  private onSlotChange(_event: Event) {
    const productElement = this.querySelector<HTMLElement>('[slot="product-variant-select-data"]');
    if (!productElement) {
      return;
    }

    this.product.id = parseInt(productElement.dataset.id, 10);
    this.product.main_image = productElement.dataset.mainImage;
    this.product.type = productElement.dataset.type;
    this.product.title = productElement.dataset.title;
    this.product.url = productElement.dataset.url;

    const productOptionGroupEls = productElement.querySelectorAll('product-option-group');

    // Clear option groups that may have already been set.
    this.product.option_groups = [];

    for (const productOptionGroupEl of productOptionGroupEls) {
      const optionGroup: ProductVariantOptionGroup = {
        name: '',
        selected_option: '',
        options: []
      };

      optionGroup.name = productOptionGroupEl.dataset.name;

      // If the form is already created, this will fetch the form fields to get the correct checked
      // value.

      const fieldset = this.shadowRoot.querySelector<HTMLFieldSetElement>(
        `fieldset[id="${optionGroup.name}"]`);
      const inputElement = fieldset?.querySelector<HTMLInputElement>(
        `input[name="${optionGroup.name}"]:checked`);
      const checkedOption = inputElement?.value;

      if (checkedOption) {
        productOptionGroupEl.dataset.selectedOption = checkedOption;
      }

      optionGroup.selected_option = productOptionGroupEl.dataset.selectedOption;

      const productOptionEls = productOptionGroupEl.querySelectorAll('product-option');

      for (const productOptionEl of productOptionEls) {
        const productOption: ProductVariantOption = {
          value: '',
          variants: []
        };

        productOption.value = productOptionEl.dataset.value;

        const variantEls = productOptionEl.querySelectorAll('variant-element');

        for (const variantEl of variantEls) {
          const variant = {
            available: variantEl.dataset.available === 'true',
            compare_at_price: parseInt(variantEl.dataset.compareAtPrice, 10),
            id: parseInt(variantEl.dataset.id, 10),
            price: parseInt(variantEl.dataset.price, 10),
            sku: variantEl.dataset.sku,
            title: variantEl.dataset.title
          };

          productOption.variants.push(variant);
        }

        optionGroup.options.push(productOption);
      }

      this.product.option_groups.push(optionGroup);
    }
  }

  private onFormChange(_event: Event) {
    this.update();

    if (this.dataset.updateUrl === 'true' && this.selectedVariantId) {
      const url = new URL(location.href);
      url.searchParams.set('variant', this.selectedVariantId.toString());
      history.replaceState({}, '', url);
    }

    type Detail = WindowEventMap['product-variant-option-changed']['detail'];
    const changeEvent = new CustomEvent<Detail>('product-variant-option-changed', {
      detail: this.product
    });
    dispatchEvent(changeEvent);
  }

  private update() {
    for (const fieldsetEl of this.shadowRoot.querySelectorAll('fieldset')) {
      const optionGroupName = fieldsetEl.id;
      const optionDivs = fieldsetEl.querySelectorAll('div');
      for (const optionDiv of optionDivs) {
        const inputEl = optionDiv.querySelector('input');
        if (inputEl.checked) {
          const optionGroup = this.product.option_groups
            .find(group => group.name === optionGroupName);
          optionGroup.selected_option = inputEl.value;
        }
      }
    }

    let variantsToFilter = this.getVariants();

    for (const optionGroup of this.product.option_groups) {
      variantsToFilter = variantsToFilter.filter(variant =>
        variant[optionGroup.name] === optionGroup.selected_option);
    }

    if (variantsToFilter.length === 1) {
      this.dataset.selectedVariantId = variantsToFilter[0].id.toString();
    }

    const variants = this.getVariants();

    const selectedOptions: Option[] = [];

    for (const fieldsetEl of this.shadowRoot.querySelectorAll('fieldset')) {
      const optionGroupName = fieldsetEl.id;

      const optionDivs = fieldsetEl.querySelectorAll('div');
      for (const optionDiv of optionDivs) {
        const inputEl = optionDiv.querySelector('input');
        const labelEl = optionDiv.querySelector('label');

        const currentOption: Option = {
          optionGroup: optionGroupName,
          option: inputEl.value
        };

        const applicableVariants = filterApplicableVariants(variants, currentOption,
          selectedOptions);

        if (inputEl.checked) {
          labelEl.classList.add('selected');
        } else {
          labelEl.classList.remove('selected');
        }

        if (isOptionHidden(applicableVariants)) {
          labelEl.style.display = 'none';
          labelEl.setAttribute('aria-hidden', 'true');

          inputEl.style.display = 'none';
          inputEl.setAttribute('aria-hidden', 'true');
        } else if (isOptionOutOfStock(applicableVariants)) {
          labelEl.classList.add('out-of-stock');
        } else {
          labelEl.style.display = 'inline';
          labelEl.setAttribute('aria-hidden', 'false');
          labelEl.classList.remove('out-of-stock');

          inputEl.style.display = 'inline';
          inputEl.setAttribute('aria-hidden', 'false');
        }
      }

      const selectedOption = this.product.option_groups
        .find(i => i.name === optionGroupName)
        ?.selected_option;

      if (selectedOption) {
        selectedOptions.push({
          optionGroup: optionGroupName,
          option: selectedOption
        });
      }
    }
  }

  private createForm() {
    const form = document.createElement('form');

    for (const optionGroup of this.product.option_groups) {
      const fieldset = document.createElement('fieldset');

      // We cannot assume this element is being rendered on a Shopify product page. If this is not
      // on a product page Shopify's servers will not automatically assign the
      // selected_or_default_variant based on the "variant" query parameter. If this occurs the
      // private property representing the value of the "variant" query parameter will differ from
      // the optionGroup.selected_option value. We must check, and if not the same update the
      // optionGroup.selected_option value to match and emit the appropriate
      // "product-variant-option-changed" so downstream listeners such as "add-to-cart" are aware.
      // option value to properly ensure propagation.

      // TODO: this is the selected option, not the selected variant, this variable is a misnomer
      // and should be renamed

      let selectedVariant;
      for (const option of optionGroup.options) {
        // TODO: do not define functions in for loops. an anonymous function is a function. please
        // rewrite this to not use the find method.

        if (option.variants.find(variant => variant.id === this.product.selected_variant_id)) {
          selectedVariant = option;
        }
      }

      if (!selectedVariant) {
        return;
      }

      if (selectedVariant?.value !== optionGroup.selected_option) {
        optionGroup.selected_option = selectedVariant.value;
        type Detail = WindowEventMap['product-variant-option-changed']['detail'];
        const changeEvent = new CustomEvent<Detail>('product-variant-option-changed', {
          detail: this.product
        });
        dispatchEvent(changeEvent);
      }

      fieldset.setAttribute('id', optionGroup.name);

      const legend = document.createElement('legend');
      legend.textContent = `Choose ${optionGroup.name}`;
      fieldset.appendChild(legend);

      for (const option of optionGroup.options) {
        const div = document.createElement('div');
        const input = document.createElement('input');

        input.setAttribute('type', 'radio');
        input.setAttribute('name', optionGroup.name);
        input.setAttribute('value', option.value);
        input.setAttribute('id', option.value);

        if (option.value === optionGroup.selected_option) {
          input.setAttribute('checked', 'checked');
        }

        div.appendChild(input);

        const label = document.createElement('label');
        label.setAttribute('for', option.value);
        label.textContent = option.value;
        div.appendChild(label);

        fieldset.appendChild(div);
      }

      form.appendChild(fieldset);
    }

    this.shadowRoot.appendChild(form);
  }

  private getVariants() {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    const variants = new Map<number, VariantExtended>();

    for (const optionGroup of this.product.option_groups) {
      for (const option of optionGroup.options) {
        for (const variant of option.variants) {
          // eslint-disable-next-line @typescript-eslint/no-deprecated
          const mappedVariant = variants.get(variant.id) || <VariantExtended>{};
          mappedVariant[optionGroup.name] = option.value;
          mappedVariant.selected = this.selectedVariantId === variant.id;
          variants.set(variant.id, { ...mappedVariant, ...variant });
        }
      }
    }

    return [...variants.values()];
  }
}

interface Option {
  option: string;
  optionGroup: string;
}

interface ProductVariantBase {
  /**
   * Whether the variant has inventory in stock and is available for sale.
   */
  available: boolean;

  backorder_message: string;

  compare_at_price: number;

  customAttributes: {
    /**
     * Key or name of the attribute.
     */
    key: string;

    /**
     * Value of the attribute.
     */
    value: string;
  }[];

  /**
   * Variant id
   */
  id: number;

  price: number;

  sku: string;

  title: string;
}

interface ProductVariant extends Partial<ProductVariantBase> {
  selected: boolean;
}

/**
 * Represents a string that is not one of the keys of the Variant type
 */
type NotVariantKeys = string & { [K in keyof ProductVariant]?: never };

/**
 * Extends the Variant type with extra property which reflects the name of the product option group
 * Example:
 * {
 *   ...Variant,
 *   Color: "Blush"
 * }
 *
 * @deprecated this should never have been implemented this way, this is too complex, avoid mixing
 * dynamic typing with static typing, this does not follow our conventions. probably what should
 * be done is that the name of the option group should be a nested object with key and value
 * properties
 */
type VariantExtended = ProductVariant & { [K in NotVariantKeys]: unknown };

// eslint-disable-next-line @typescript-eslint/no-deprecated
function filterApplicableVariants(variants: VariantExtended[], currentOption: Option,
  selectedOptions: Option[] = []) {
  const filteredVariants = variants
    .filter(variant => variant[currentOption.optionGroup] === currentOption.option);
  let applicableVariants = filteredVariants;

  if (selectedOptions.length > 0) {
    applicableVariants = filteredVariants
      .filter(variant => selectedOptions.every(i => variant[i.optionGroup] === i.option));
  }

  return applicableVariants;
}

// eslint-disable-next-line @typescript-eslint/no-deprecated
function isOptionHidden(variants: VariantExtended[] = []) {
  return variants.length === 0;
}

// eslint-disable-next-line @typescript-eslint/no-deprecated
function isOptionOutOfStock(variants: VariantExtended[] = []) {
  return variants.length > 0 && variants.every(isVariantUnavailable);
}

// eslint-disable-next-line @typescript-eslint/no-deprecated
function isVariantUnavailable(variant: VariantExtended) {
  return !variant.available;
}

interface ProductVariantOptionGroup {
  name: string;
  options: ProductVariantOption[];
  position?: number;
  selected_option: string;
}

interface ProductVariantOption {
  value: string;
  variants: Partial<{
    /**
     * Whether the variant has inventory in stock and is available for sale.
     */
    available: boolean;

    backorder_message: string;

    compare_at_price: number;

    customAttributes: {
      /**
       * Key or name of the attribute.
       */
      key: string;

      /**
       * Value of the attribute.
       */
      value: string;
    }[];

    hideBackorderInCollection: string;

    /**
     * Variant id
     */
    id: number;

    price: number;

    sku: string;

    title: string;
  }>[];
}

interface Product {
  /**
   * Product inventory availability
   */
  available: boolean;

  /**
   * Product award image
   */
  award_badge_image: string;

  /**
   * Product award tag
   */
  award_tag: string;

  /**
   * Product badge type
   */
  badge_type: '' | 'best-seller' | 'favorite' | 'ideal-for-you' | 'new' | 'travel-friendly';

  /**
   * Product main image
   */
  hover_image: string;

  /**
   * Shopify product id
   */
  id: number;

  /**
   * Product main image loading method
   */
  lazyload: boolean;

  /**
   * Product main image
   */
  main_image: string;

  /**
   * Product metafields discount code (used to promote public discount codes)
   */
  metafields_discount_code: string;

  /**
   * Product metafields discount override to use one-off messages instead of generated text
   */
  metafields_discount_override: string;

  /**
   * Product metafields discount percentage (used to render public discount code prices)
   */
  metafields_discount_percentage: number;

  /**
   * Product metafields subtitle
   */
  metafields_subtitle: string;

  /**
   * Product metafields title
   */
  metafields_title: string;

  option_groups: {
    name: string;
    options: {
      value: string;
      variants: Partial<{
        /**
         * Whether the variant has inventory in stock and is available for sale.
         */
        available: boolean;

        backorder_message: string;

        compare_at_price: number;

        customAttributes: {
          /**
           * Key or name of the attribute.
           */
          key: string;

          /**
           * Value of the attribute.
           */
          value: string;
        }[];

        hideBackorderInCollection: string;

        /**
         * Variant id
         */
        id: number;

        price: number;

        sku: string;

        title: string;
      }>[];
    }[];
    position?: number;
    selected_option: string;
  }[];

  /**
   * Product yotpo reviews average value
   */
  reviews_average: number;

  /**
   * Product yotpo reviews count
   */
  reviews_count: number;

  /**
   * Selected variant id
   */
  selected_variant_id: number;

  /**
   * Product sku
   */
  sku: string;

  /**
   * Product tags
   */
  tags: string;

  /**
   * Product title
   */
  title: string;

  /**
   * Product type
   *
   * @example "hair-care"
   */
  type: string;

  /**
   * Shopify product url
   */
  url: string;

  /**
   * Represents a product with multiple variants that is part of a value set. This value is used to
   * inform any subscribers consuming the product-variant-option-changed event that a product with
   * an ID not matching the parent product still requires it's DOM data indicating the actively
   * selected product.
   *
   * @todo this should be removed
   *
   * @deprecated
   */
  value_set_parent_id: number;

  /**
   * A list of the product's variants with some information about variant
   */
  variants: {
    /**
     * Whether the variant is in stock
     */
    available: boolean;

    /**
     * Whether the variant is on backorder
     */
    backorder: string;

    /**
     * The MSRP, or original sales price before discounts, per unit
     */
    compare_at_price: number;

    /**
     * The currency code associated with the price
     */
    currency_code: string;

    /**
     * @todo clarify what this means
     */
    hide_backorder_in_collection: string;

    /**
     * Variant id
     *
     * @example 12345568943
     */
    id: number;

    /**
     * The value of the variant for the first product option. If there's no first product option,
     * then nil is returned.
     */
    option1: string;

    /**
     * The value of the variant for the second product option. If there's no first product option,
     * then nil is returned.
     */
    option2: string;

    /**
     * The value of the variant for the third product option. If there's no first product option,
     * then nil is returned.
     */
    option3: string;

    /**
     * The price of the variant
     *
     * @todo clarify, is this in cents?
     */
    price: number;

    /**
     * Whether this variant is the currently selected variant out of all the variants for the
     * product
     */
    selected: boolean;

    /**
     * The Stock Keeping Unit associated with the variant. This is another kind of product id.
     */
    sku: string;

    /**
     * The title of the variant.
     */
    title: string;
  }[];
}

/**
 * This event is triggered when a user changes a variant option of a product, such as size or color,
 * on a web page.
 */
type ProductVariantOptionChangedEvent = CustomEvent<Partial<Product>>;

declare global {
  interface WindowEventMap {
    'product-variant-option-changed': ProductVariantOptionChangedEvent;
  }

  interface HTMLElementTagNameMap {
    'product-variant-select': ProductVariantSelect;
  }
}

if (!customElements.get('product-variant-select')) {
  customElements.define('product-variant-select', ProductVariantSelect);
}
